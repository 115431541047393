import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import "./Bill.css";

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { subDays } from "date-fns";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AsaasIcon } from "../../../../assets/img/asaas.svg";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { ICapBill } from "../../../types/Cap/Bill/ICapBill";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import { DatePicker } from "@mui/x-date-pickers";
import { formataDateLocal } from "../../../utils/date-utils";
import FullScreenDialog from "../../../../components/elements/Dialog/ConfirmDialogCap";
import ConfirmDialogCap from "../../../../components/elements/Dialog/ConfirmDialogCap";
import { display, flexbox } from "@mui/system";
import billsService from "../../../services/admin/BillsService";
import { ISimpleList } from "../../../types/Generic/ISimpleList";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IDownloadFile } from "../../../types/Generic/IDownloadFile";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CapBill = () => {
  let navigate = useNavigate();

  const { selectedAccount, addMessages, setaLoading } = useContext(GlobalContext);

  const initialDays = 30;

  const [initialDate, setInitialDate] = useState<Date>(subDays(new Date(), initialDays));
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [openExport, setOpenExport] = React.useState(false);
  const handleOpenExport = () => setOpenExport(true);
  const handleCloseExport = () => setOpenExport(false);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [inputSearchValue, setInputSearchValue] = useState("");

  const [bill, setBill] = useState<ICapBill[]>([]);

  const [statusList, setStatusList] = useState<ISimpleList[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [selectedStatus, setSelectedStatus] = useState<string[]>([" "]);

  const [selectedFile, setSelectedFile] = useState<File>();

  const [sort, setSort] = useState<string>("b.DUE_DATE,desc");

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchBill(
    page: number,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
    selectedStatus?: string[]
  ) {
    const response = await billsService.searchFilteredCapBills(
      addMessages,
      setaLoading,
      navigate,
      page,
      sort,
      initialDate,
      finalDate,
      searchQuery,
      selectedStatus
    );
    if (response) {
      setTotalPages(response.totalPages);
      setBill(response.content);
      setCurrentPage(page + 1);
    } else {
      setTotalPages(0);
      setBill([]);
      addMessages(["Não houve resposta do serviço de faturas"]);
    }
  }

  async function searchPaymentsStatusList() {
    const response = await billsService.searchPaymentsStatusList(
      addMessages,
      setaLoading,
      navigate
    );
    if (response) {
      setStatusList(response);
    } else {
      setTotalPages(0);
      setBill([]);
      addMessages(["Não houve resposta do serviço de faturas"]);
    }
  }

  async function deleteBill(id: number) {
    let url = `/v1/cap/bill/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      addMessages(response.messages);
      searchBill(0, sort, initialDate, finalDate, debouncedSearchValue, selectedStatus);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleAsaasExport = async (initialDate?: Date, finalDate?: Date, searchQuery?: string) => {
    let url = `v1/cap/bill/export/asaas`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      billSerie: searchQuery,
      billCode: searchQuery,
    };
    try {
      const response = await sendPost<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      addMessages(response.messages);
      handleCloseExport();
      searchBill(
        currentPage - 1,
        sort,
        initialDate,
        finalDate,
        debouncedSearchValue,
        selectedStatus
      );
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  const handleFileImport = async () => {
    if (selectedFile === undefined && !selectedFile) {
      alert("Favor selecionar um arquivo para enviar");
      return;
    }
    let url = `v1/cap/bill/import/csv`;
    const dados = new FormData();
    dados.append("file", selectedFile);

    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await sendPost<IDownloadFile>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
        headers
      );

      const byteCharacters = atob(response.fileBytes);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.fileType });
      const urlLink = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlLink;
      link.download = response.fileName;
      link.click();

      deselectFile();
      handleCloseImport();
      searchBill(0, sort, initialDate, finalDate, debouncedSearchValue, selectedStatus);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  function deselectFile() {
    setSelectedFile(undefined);
  }

  function handleChange(file: File) {
    setSelectedFile(file);
  }

  function handleClickExcluirAll(): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir todas as faturas?`)) {
      deleteBillByFilter();
    }
    return;
  }

  async function deleteBillByFilter() {
    let url = `v1/cap/bill/delete-by-filter`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: debouncedSearchValue,
      billStatus: selectedStatus,
    };
    try {
      const response = await sendPost<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      if (response.messages) {
        addMessages(response.messages);
      }
      searchBill(0, sort, initialDate, finalDate, debouncedSearchValue, selectedStatus);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  function formatarNomeArquivo(nomeArquivo: string) {
    // Removendo a extensão
    const nomeSemExtensao = nomeArquivo.split(".").slice(0, -1).join(".");
    const partes = nomeArquivo.split(".");
    const extensao = partes.length > 1 ? partes.pop() : "";

    // Limitando a 10 caracteres e adicionando '...'
    if (nomeArquivo.length > 27) {
      return (
        nomeSemExtensao.substring(0, 16) +
        " ... " +
        nomeSemExtensao.substring(nomeSemExtensao.length - 3) +
        "." +
        extensao
      );
    } else {
      return nomeArquivo;
    }
  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFez) {
        searchBill(currentPage - 1, sort, initialDate, finalDate, value);
      }
      jaFez = true;

      return;
    }, 1000),
    []
  ); // 1000 ms de delay

  useEffect(() => {
    searchPaymentsStatusList();
    searchBill(0, sort, initialDate, finalDate, debouncedSearchValue, selectedStatus);
  }, []);

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchBill(page - 1, sort, initialDate, finalDate, debouncedSearchValue, selectedStatus);
  };

  function handleClickEdit(bill: ICapBill): void {
    console.log(bill);
    navigate(`/area-logada/cap/edit-bill/${bill.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/cap/new-bill`);
  }

  function handleClickDelete(
    bill: ICapBill
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (
      confirm(
        `Tem certeza que deseja excluir a fatura ${bill.suplierName} - ${bill.billSerie} - ${
          bill.billCode
        } ${bill.billDescription ? " - " + bill.billDescription.substring(0, 20) + " ... " : ""}`
      )
    ) {
      deleteBill(bill.id);
    }
    return;
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchBill(
      currentPage - 1,
      sortNovo,
      initialDate,
      finalDate,
      debouncedSearchValue,
      selectedStatus
    );
  }

  function refreshFunction() {
    searchBill(currentPage - 1, sort, initialDate, finalDate, debouncedSearchValue, selectedStatus);
  }

  const columns = [
    { displayName: "Fornecedor", columnName: "p.NAME" },
    { displayName: "Status", columnName: "b.BILL_STATUS" },
    { displayName: "Descrição", columnName: "b.BILL_DESCRIPTION" },
    { displayName: "Vencimento", columnName: "b.DUE_DATE" },
    { displayName: "Vlr Pagar", columnName: "b.VLR_PAGAR" },
    { displayName: "Ações", columnName: null },
  ];

  function handleChangeStatus(event: SelectChangeEvent<string[]>, child: ReactNode): void {
    setSelectedStatus(event.target.value as string[]);
    searchBill(
      0,
      sort,
      initialDate,
      finalDate,
      debouncedSearchValue,
      event.target.value as string[]
    );
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            {selectedAccount === "1" && (
              <Modal
                keepMounted
                open={openExport}
                onClose={handleCloseExport}
                aria-labelledby="keep-mounted-export-modal-title"
                aria-describedby="keep-mounted-export-modal-description"
              >
                <Box sx={style}>
                  <Typography id="keep-mounted-export-modal-title" variant="h6" component="h2">
                    Exportação de Contas à Pagar
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      startIcon={<AsaasIcon style={{ width: "24px", height: "24px" }} />}
                      onClick={() =>
                        handleAsaasExport(initialDate, finalDate, debouncedSearchValue)
                      }
                    >
                      Exportar para Asaas
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            )}
            <Modal
              keepMounted
              open={openImport}
              onClose={handleCloseImport}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                  Importação de Contas à Pagar
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button
                    component="label"
                    variant="contained"
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <CloudArrowUpIcon />
                      </SvgIcon>
                    }
                  >
                    Selecionar arquivo
                    <VisuallyHiddenInput
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          // Acessar o primeiro arquivo
                          const file = e.target.files[0];

                          // Verificar se o arquivo é um CSV
                          if (file.name.endsWith(".csv")) {
                            handleChange(file);
                          } else {
                            alert("Por favor, selecione um arquivo CSV.");
                          }
                        }
                      }}
                    />
                  </Button>
                  {selectedFile && <>{formatarNomeArquivo(selectedFile.name)}</>}

                  <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowRightIcon />
                      </SvgIcon>
                    }
                    onClick={handleFileImport}
                    sx={{ width: "195px" }}
                  >
                    Importar do Arquivo
                  </Button>
                </Stack>
              </Box>
            </Modal>

            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack spacing={1}>
                <Titulo>Contas à Pagar</Titulo>
                <table>
                  <tr>
                    <td>
                      <ConfirmDialogCap
                        initialDueDate={initialDate}
                        finalDueDate={finalDate}
                        search={debouncedSearchValue}
                        selectedStatus={selectedStatus}
                        refreshFunction={refreshFunction}
                      />
                    </td>
                    <td>
                      <Button
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ArrowUpOnSquareIcon />
                          </SvgIcon>
                        }
                        onClick={handleOpenImport}
                      >
                        Importar
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ArrowDownOnSquareIcon />
                          </SvgIcon>
                        }
                        onClick={selectedAccount === "1" ? handleOpenExport : undefined}
                        disabled={selectedAccount !== "1"}
                      >
                        Exportar
                      </Button>
                    </td>
                    <td>
                      <Button
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <DeleteForeverIcon />
                          </SvgIcon>
                        }
                        onClick={handleClickExcluirAll}
                      >
                        Excluir
                      </Button>
                    </td>
                  </tr>
                </table>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                <TextField
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <Select
                  labelId="status-simple-select-label"
                  id="status-simple-select"
                  value={selectedStatus}
                  label="Status"
                  multiple
                  style={{
                    width: 200,
                    overflowX: "auto",
                  }}
                  name="selectedStatus"
                  onChange={handleChangeStatus}
                >
                  <MenuItem key={0} value=" " selected={true}>
                    Todos os Status
                  </MenuItem>
                  {statusList.map((row) => (
                    <MenuItem key={row.id} value={row.label}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
                <DatePicker
                  label="Data inicial"
                  value={initialDate}
                  onChange={(newValue: Date | null) => {
                    setInitialDate(newValue ? newValue : subDays(new Date(), initialDays));
                    searchBill(
                      0,
                      sort,
                      newValue ? newValue : subDays(new Date(), initialDays),
                      finalDate,
                      debouncedSearchValue,
                      selectedStatus
                    );
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 200 }} />
                  )}
                />
                <DatePicker
                  label="Data final"
                  value={finalDate}
                  onChange={(newValue: Date | null) => {
                    setFinalDate(newValue ? newValue : new Date());
                    searchBill(
                      0,
                      sort,
                      initialDate,
                      newValue ? newValue : new Date(),
                      debouncedSearchValue,
                      selectedStatus
                    );
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 200 }} />
                  )}
                />
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {bill.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>
                        <CustomActionBox>
                          {row.suplierCpfCnpj + " - " + row.suplierName}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>{row.billStatus}</CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>{row.billDescription}</CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          {formataDateLocal(row.dueDate + "T00:00:00-03:00")}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          {`R$ ${row.vlrPagar.toLocaleString("pt-BR")}`}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEdit} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickDelete} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default CapBill;
