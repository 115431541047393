import { Autocomplete, AutocompleteRenderInputParams, Box, Button, Container, Grid, Stack, SvgIcon, TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from 'react';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';



import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ICapBill } from '../../../../types/Cap/Bill/ICapBill';
import { ICapBillNewData } from '../../../../types/Cap/Bill/ICapBillNewData';
import { ICreClient } from '../../../../types/Cre/Client/ICreClient';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { sendPost } from '../../../../utils/httpUtils';
import './NewBill.css';
import { ICapCategory } from '../../../../types/Cap/Category/ICapCategory';
import { ICapSuplier } from '../../../../types/Cap/Suplier/ICapSuplier';
import { ICapSuplierNewData } from '../../../../types/Cap/Suplier/ICapSuplierNewData';
import { ISimpleList } from '../../../../types/Generic/ISimpleList';
import billsService from '../../../../services/admin/BillsService';
import NumericFormatCustom from '../../../../../components/elements/NumericFormatCustom';

const NewCapBill = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const [totalPages, setTotalPages] = useState<number>(0);

    //Inicia todas as propriedadese do objeto como string vazia
    const [bill, setBill] = useState<ICapBillNewData>({
        suplierId: 0,
        billDescription: '',
        billDate: new Date(),
        billStatus: 'Aberto',
        dueDate: new Date(),
        billSerie: '',
        billCode: '',
        categoryId: null,
        vlrPagar: 0,
        vlrDevolucao: 0,
        vlrDesconto: 0,
        saldoDuplicata: 0
    } as ICapBillNewData);

    const [suplier, setSuplier] = useState<ICapSuplierNewData>({
        peopleName: '',
        peopleCpfCnpj: '',
        peopleFantasyName: '',
        peopleEmail: '',
        peoplePhone: '',
        peopleMobilePhone: '',
        addressStreet: '',
        addressNumber: '',
        addressComplement: '',
        addressNeighborhood: '',
        addressCity: '',
        addressState: '',
        addressPostalCode: '',
        addressRegion: '',
        municipalInscription: '',
        stateInscription: '',
        observations: '',
        peopleInstagram: '',
        suplierNumber: '',
        peopleAdditionalEmails: ''
    } as ICapSuplierNewData);

    const [suplierList, setSuplierList] = useState<ICapSuplier[]>([] as ICapSuplier[]);

    const [statusList, setStatusList] = useState<ISimpleList[]>([] as ISimpleList[]);

    const [categoriesList, setCategoriesList] = useState<ICapCategory[]>([] as ICapCategory[]);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cap/bill')
    }

    async function searchSupliers(suplierName: string) {

        let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
        const dados = { suplierName: suplierName }
        try {
            const response = await sendPost<IPaginatedList<ICapSuplier>>(url, dados, addMessages, setaLoading, navigate);
            setSuplierList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchPaymentsStatusList() {
        const response = await billsService.searchPaymentsStatusList(addMessages, setaLoading, navigate);
        if (response) {
          setStatusList(response);
        } else {
          addMessages(["Não houve resposta do serviço de faturas"]);
        }
      }

    function validateForm() {
        //Aplicar trim em todos os campos
        bill.billSerie = bill.billSerie.trim();
        bill.billCode = bill.billCode.trim();
        if (bill.billSerie === '') {
            addMessages(['Série é obrigatória']);
            return false;
        }
        if (bill.billCode === '') {
            addMessages(['Codigo é obrigatório']);
            return false;
        }
        if (bill.billStatus === '') {
            addMessages(['Status é obrigatório']);
            return false;
        }
        if (bill.suplierId <= 0) {
            addMessages(['Fornecedor é obrigatório']);
            return false;
        }
        if (bill.dueDate === undefined) {
            addMessages(['Data de Vencimento é obrigatória']);
            return false;
        }
        if (bill.vlrPagar && bill.vlrPagar <= 0) {
            addMessages(['Valor a Pagar deve ser maior que zero']);
            return false;
        }
        if (bill.saldoDuplicata && bill.saldoDuplicata <= 0) {
            addMessages(['Saldo da Duplicata deve ser maior que zero']);
            return false;
        }
        if (bill.saldoDuplicata !== null && bill.vlrPagar !== null && bill.vlrPagar < bill.saldoDuplicata) {
            addMessages(['Valor a Pagar deve ser maior ou igual ao Saldo da Duplicata']);
            return false;
        }
        if (bill.vlrDevolucao && bill.vlrDevolucao < 0) {
            addMessages(['Valor de Devolução deve ser maior ou igual a zero']);
            return false;
        }
        if (bill.vlrDesconto && bill.vlrDesconto < 0) {
            addMessages(['Valor de Desconto deve ser maior ou igual a zero']);
            return false;
        }

        return true;
    }
    async function handleClickSave() {
        if (!validateForm()) {
            return;
        }

        let url = `v1/cap/bill/new`
        /*Record do backend
        @NotEmpty String name, @NotEmpty String cpfCnpj, String number, String fantasyName, String email, String phone, String mobilePhone, String address, String addressNumber, String complement, String province, String city, String state, String postalCode, String region, String municipalInscription, String stateInscription, String observations, String externalReference, String additionalEmails*/
        const dados = {
            suplierId: bill.suplierId,
            billDescription: bill.billDescription,
            billDate: bill.billDate,
            billStatus: bill.billStatus,
            dueDate: bill.dueDate,
            billSerie: bill.billSerie,
            billCode: bill.billCode,
            categoryId: bill.categoryId,
            vlrPagar: bill.vlrPagar,
            vlrDevolucao: bill.vlrDevolucao,
            vlrDesconto: bill.vlrDesconto,
            saldoDuplicata: bill.saldoDuplicata
        }
        console.log("dados")
        console.log(dados)
        try {
            const response = await sendPost<ICapBill>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cap/bill')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function searchSuplier(suplierName: string) {

        let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
        const dados = { suplierName: suplierName }
        try {
            const response = await sendPost<IPaginatedList<ICapSuplier>>(url, dados, addMessages, setaLoading, navigate);
            setSuplierList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchCategories(categoryName: string) {

        let url = `v1/cap/category/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: categoryName }
        try {
            const response = await sendPost<IPaginatedList<ICapCategory>>(url, dados, addMessages, setaLoading, navigate);
            setCategoriesList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setBill(prevBill => ({ ...prevBill, [name]: valor }));
    };

    useEffect(() => {
        setaLoading(true);
        searchSuplier('');
        searchCategories('');
        searchPaymentsStatusList();
        setaLoading(false);
    }, []);

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Fatura</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-suplier"
                                    options={suplierList}
                                    getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do fornecedor como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "suplierId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Fornecedor" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchSupliers(newInputValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-status"
                                    options={statusList}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option.label} // Use o nome da categoria como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={statusList.find(billStatus => billStatus.label === bill.billStatus) || null}
                                    renderInput={(params) => <TextField {...params} label="Status" required />}
                                    defaultValue={statusList[0]}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "billStatus": newValue?.label ? newValue.label : ''
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descrição"
                                    name="billDescription"
                                    onChange={handleChange}
                                    value={bill.billDescription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Série"
                                    name="billSerie"
                                    onChange={handleChange}
                                    required
                                    value={bill.billSerie}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Codigo"
                                    name="billCode"
                                    onChange={handleChange}
                                    required
                                    value={bill.billCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Data fatura"
                                    value={bill.billDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "billDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='billDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Vencimento fatura"
                                    value={bill.dueDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "dueDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='dueDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-wallet"
                                    options={categoriesList}
                                    getOptionLabel={(option) => (option.upperCategoryName ? option.upperCategoryName + ' -> ' : '') + option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "categoryId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categoria" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchCategories(newInputValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor a Pagar"
                                    value={bill.vlrPagar}
                                    onChange={(values: { target: { name: string; value: string } }) => setBill(prevBill => ({ ...prevBill, "vlrPagar": Number(values.target.value) }))}
                                    name="vlrPagar"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor Desconto"
                                    value={bill.vlrDesconto}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "vlrDesconto": Number(values.target.value) }))}
                                    name="vlrDesconto"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor da Devolução"
                                    value={bill.vlrDevolucao}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "vlrDevolucao": Number(values.target.value) }))}
                                    name="vlrDevolucao"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Saldo da Duplicata"
                                    value={bill.saldoDuplicata}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "saldoDuplicata": Number(values.target.value) }))}
                                    name="saldoDuplicata"
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={2}>
                                <Button onClick={handleClickSave} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default NewCapBill;


