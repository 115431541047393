import { Autocomplete, Box, Button, Container, Grid, Stack, SvgIcon, TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from 'react';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';



import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ICreBill } from '../../../../types/Cre/Bill/ICreBill';
import { IBillNewData } from '../../../../types/Cre/Bill/ICreBillNewData';
import { ICreClient } from '../../../../types/Cre/Client/ICreClient';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IWallet } from '../../../../types/Cre/Wallet/IWallet';
import { sendPost } from '../../../../utils/httpUtils';
import './NewBill.css';
import { ICreCategory } from '../../../../types/Cre/Category/ICreCategory';
import { ca } from 'date-fns/locale';
import { ISimpleList } from '../../../../types/Generic/ISimpleList';
import billsService from '../../../../services/admin/BillsService';
import { IMaskInput } from 'react-imask';
import NumericFormatCustom from '../../../../../components/elements/NumericFormatCustom';

const NewBill = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    //Inicia todas as propriedadese do objeto como string vazia
    const [bill, setBill] = useState<IBillNewData>({
        clientId: 0,
        walletId: 0,
        billDescription: '',
        billDate: new Date(),
        dueDate: new Date(),
        billSerie: '',
        billStatus: '',
        billCode: '',
        categoryId: null,
        vlrReceber: null,
        vlrDevolucao: null,
        vlrDesconto: null,
        saldoDuplicata: null
    } as IBillNewData);

    const [clientsList, setClientsList] = useState<ICreClient[]>([] as ICreClient[]);

    const [walletsList, setWalletsList] = useState<IWallet[]>([] as IWallet[]);

    const [categoriesList, setCategoriesList] = useState<ICreCategory[]>([] as ICreCategory[]);

    const [statusList, setStatusList] = useState<ISimpleList[]>([] as ISimpleList[]);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cre/bill')
    }

    function validateForm() {
        //Aplicar trim em todos os campos
        bill.billSerie = bill.billSerie.trim();
        bill.billCode = bill.billCode.trim();
        if (bill.billSerie === '') {
            addMessages(['Série é obrigatória']);
            return false;
        }
        if (bill.billCode === '') {
            addMessages(['Codigo é obrigatório']);
            return false;
        }
        if (bill.clientId <= 0) {
            addMessages(['Cliente é obrigatório']);
            return false;
        }
        if (bill.walletId <= 0) {
            addMessages(['Carteira é obrigatória']);
            return false;
        }
        if (bill.dueDate === undefined) {
            addMessages(['Data de Vencimento é obrigatória']);
            return false;
        }
        if (bill.vlrReceber && bill.vlrReceber <= 0) {
            addMessages(['Valor a Receber deve ser maior que zero']);
            return false;
        }
        if (bill.saldoDuplicata && bill.saldoDuplicata <= 0) {
            addMessages(['Saldo da Duplicata deve ser maior que zero']);
            return false;
        }
        if (bill.vlrReceber && bill.saldoDuplicata && bill.vlrReceber < bill.saldoDuplicata) {
            addMessages(['Valor a Receber deve ser maior ou igual ao Saldo da Duplicata']);
            return false;
        }
        if (bill.vlrDevolucao && bill.vlrDevolucao < 0) {
            addMessages(['Valor de Devolução deve ser maior ou igual a zero']);
            return false;
        }
        if (bill.vlrDesconto && bill.vlrDesconto < 0) {
            addMessages(['Valor de Desconto deve ser maior ou igual a zero']);
            return false;
        }

        return true;
    }
    async function handleClickSave() {
        if (!validateForm()) {
            return;
        }

        let url = `v1/cre/bill/new`
        /*Record do backend
        @NotEmpty String name, @NotEmpty String cpfCnpj, String number, String fantasyName, String email, String phone, String mobilePhone, String address, String addressNumber, String complement, String province, String city, String state, String postalCode, String region, String municipalInscription, String stateInscription, String observations, String externalReference, String additionalEmails*/
        const dados = {
            clientId: bill.clientId,
            walletId: bill.walletId,
            billDescription: bill.billDescription,
            billDate: bill.billDate,
            dueDate: bill.dueDate,
            billSerie: bill.billSerie,
            billStatus: bill.billStatus,
            billCode: bill.billCode,
            categoryId: bill.categoryId,
            vlrReceber: bill.vlrReceber,
            vlrDevolucao: bill.vlrDevolucao,
            vlrDesconto: bill.vlrDesconto,
            saldoDuplicata: bill.saldoDuplicata
        }
        console.log("dados")
        console.log(dados)
        try {
            const response = await sendPost<ICreBill>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cre/bill')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function searchClients(clientName: string) {

        let url = `v1/client/list?size=10&page=0&sort=NAME,asc`;
        const dados = { clientName: clientName }
        try {
            const response = await sendPost<IPaginatedList<ICreClient>>(url, dados, addMessages, setaLoading, navigate);
            setClientsList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchWallets(walletName: string) {

        let url = `v1/wallet/list?size=10&page=0&sort=WALLET_NAME,asc`;
        const dados = { walletName: walletName }
        try {
            const response = await sendPost<IPaginatedList<IWallet>>(url, dados, addMessages, setaLoading, navigate);
            setWalletsList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchCategories(categoryName: string) {

        let url = `v1/cre/category/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: categoryName }
        try {
            const response = await sendPost<IPaginatedList<ICreCategory>>(url, dados, addMessages, setaLoading, navigate);
            setCategoriesList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        valor = String(valor);

        setBill(prevBill => ({ ...prevBill, [name]: valor }));
    };

    const handleValueChange = (event: { target: { name: string; value: string } }) => {
        console.log("EventoTOP: ", event);
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setBill(prevBill => ({ ...prevBill, [name]: valor }));
    };

    async function searchReceiptsStatusList() {
        const response = await billsService.searchReceiptsStatusList(addMessages, setaLoading, navigate);
        if (response) {
            setStatusList(response);
        } else {
            addMessages(["Não houve resposta do serviço de faturas"]);
        }
    };

    useEffect(() => {
        setaLoading(true);
        searchClients('');
        searchWallets('');
        searchCategories('');
        searchReceiptsStatusList();
        setaLoading(false);
    }, []);

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Conta a receber</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-client"
                                    options={clientsList}
                                    getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do cliente como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "clientId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Cliente" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchClients(newInputValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-status"
                                    options={statusList}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option.label} // Use o nome da categoria como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={statusList.find(billStatus => billStatus.label === bill.billStatus) || null}
                                    renderInput={(params) => <TextField {...params} label="Status" required />}
                                    defaultValue={statusList[0]}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "billStatus": newValue?.label ? newValue.label : ''
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-wallet"
                                    options={walletsList}
                                    getOptionLabel={(option) => option.walletNumber ? (option.walletNumber + " - " + option.walletName) : option.walletName} // Use o nome do cliente como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "walletId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Carteira" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchWallets(newInputValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descrição"
                                    name="billDescription"
                                    onChange={handleChange}
                                    value={bill.billDescription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Série"
                                    name="billSerie"
                                    onChange={handleChange}
                                    required
                                    value={bill.billSerie}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Codigo"
                                    name="billCode"
                                    onChange={handleChange}
                                    required
                                    value={bill.billCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Data fatura"
                                    value={bill.billDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "billDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='billDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Vencimento fatura"
                                    value={bill.dueDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "dueDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='dueDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-wallet"
                                    options={categoriesList}
                                    getOptionLabel={(option) => (option.upperCategoryName ? option.upperCategoryName + ' -> ' : '') + option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "categoryId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categoria" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchCategories(newInputValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor a Receber"
                                    value={bill.vlrReceber}
                                    onChange={(values: { target: { name: string; value: string } }) => setBill(prevBill => ({ ...prevBill, "vlrReceber": Number(values.target.value) }))}
                                    name="vlrReceber"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor Desconto"
                                    value={bill.vlrDesconto}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "vlrDesconto": Number(values.target.value) }))}
                                    name="vlrDesconto"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor da Devolução"
                                    value={bill.vlrDevolucao}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "vlrDevolucao": Number(values.target.value) }))}
                                    name="vlrDevolucao"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Saldo da Duplicata"
                                    value={bill.saldoDuplicata}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "saldoDuplicata": Number(values.target.value) }))}
                                    name="saldoDuplicata"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSave} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default NewBill;


