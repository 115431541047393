import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Checkbox, Container, Grid, IconButton, Paper, Stack, SvgIcon, Table, TableBody, TableContainer, TableRow, TextField, TextFieldProps, Tooltip } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ICapBill } from '../../../../types/Cap/Bill/ICapBill';
import { IBillUpdateData } from '../../../../types/Cap/Bill/ICapBillUpdateData';
import { ICapCategory } from '../../../../types/Cap/Category/ICapCategory';
import { ICreClient } from '../../../../types/Cre/Client/ICreClient';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import './EditBill.css';
import { ICapSuplier } from '../../../../types/Cap/Suplier/ICapSuplier';
import { resolve } from 'path';
import { ICapPayment } from '../../../../types/Cap/Payment/ICapPayment';
import TableHeader from '../../../../../components/elements/TableHeader';
import CustomTableCell from '../../../../../components/elements/CustomTableCell';
import { formataDateLocal } from '../../../../utils/date-utils';
import { ISimpleList } from '../../../../types/Generic/ISimpleList';
import billsService from '../../../../services/admin/BillsService';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import NumericFormatCustom from '../../../../../components/elements/NumericFormatCustom';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const EditCapBill = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const billId = Number(id);

    const [bill, setBill] = useState<IBillUpdateData>(
        {
            id: billId,
            suplierId: 0,
            billDescription: '',
            billDate: new Date(),
            dueDate: new Date(),
            billSerie: '',
            billStatus: '',
            billCode: '',
            categoryId: null,
            vlrPagar: 0,
            vlrDevolucao: 0,
            vlrDesconto: 0,
            saldoDuplicata: 0,
            payments: []
        } as IBillUpdateData);

    const [supliersList, setSupliersList] = useState<ICapSuplier[]>([] as ICapSuplier[]);

    const [categoryList, setCategoryList] = useState<ICapCategory[]>([] as ICapCategory[]);

    const [sort, setSort] = useState<string>("p.NAME,asc");

    const [statusList, setStatusList] = useState<ISimpleList[]>([] as ISimpleList[]);

    const iconStyles = {
        fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
        marginRight: "8px", // Ajuste o espaçamento entre os ícones
    };

    function handleSortChange(sortNovo: string) {
        setSort(sortNovo);
    }

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cap/bill')
    }

    async function searchPaymentsStatusList() {
        const response = await billsService.searchPaymentsStatusList(addMessages, setaLoading, navigate);
        if (response) {
          setStatusList(response);
        } else {
          addMessages(["Não houve resposta do serviço de faturas"]);
        }
      }

    function validateForm() {
        //Aplicar trim em todos os campos
        bill.billSerie = bill.billSerie.trim();
        bill.billCode = bill.billCode.trim();
        if (bill.billSerie === '') {
            addMessages(['Série é obrigatória']);
            return false;
        }
        if (bill.billCode === '') {
            addMessages(['Número é obrigatório']);
            return false;
        }
        if (bill.suplierId <= 0) {
            addMessages(['Fornecedor é obrigatório']);
            return false;
        }
        if (bill.billStatus <= '') {
            addMessages(['Status é obrigatório']);
            return false;
        }
        if (bill.dueDate === undefined) {
            addMessages(['Data de Vencimento é obrigatória']);
            return false;
        }
        if (bill.vlrPagar && bill.vlrPagar <= 0) {
            addMessages(['Valor a Pagar deve ser maior que zero']);
            return false;
        }
        if (bill.saldoDuplicata && bill.saldoDuplicata <= 0) {
            addMessages(['Saldo da Duplicata deve ser maior que zero']);
            return false;
        }
        if (bill.saldoDuplicata !== null && bill.vlrPagar !== null && bill.vlrPagar < bill.saldoDuplicata) {
            addMessages(['Valor a Pagar deve ser maior ou igual ao Saldo da Duplicata']);
            return false;
        }
        if (bill.vlrDevolucao && bill.vlrDevolucao < 0) {
            addMessages(['Valor de Devolução deve ser maior ou igual a zero']);
            return false;
        }
        if (bill.vlrDesconto && bill.vlrDesconto < 0) {
            addMessages(['Valor de Desconto deve ser maior ou igual a zero']);
            return false;
        }

        return true;
    }

    async function handleClickSalvar() {
        if (!validateForm()) {
            return;
        }
        let url = `v1/cap/bill/edit/${billId}`
        console.log(url)
        const dados = {
            id: bill.id,
            suplierId: bill.suplierId,
            billDescription: bill.billDescription,
            billSerie: bill.billSerie,
            billStatus: bill.billStatus,
            billCode: bill.billCode,
            billDate: bill.billDate,
            dueDate: bill.dueDate,
            categoryId: bill.categoryId,
            vlrPagar: bill.vlrPagar,
            vlrDevolucao: bill.vlrDevolucao,
            vlrDesconto: bill.vlrDesconto,
            saldoDuplicata: bill.saldoDuplicata,
            payments: bill.payments,
        }
        console.log("dados");
        console.log(dados);
        try {
            const response = await sendPost<ICapBill>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cap/bill')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function searchSupliers(suplierName: string, suplierId: number) {

        let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
        const dados = { suplierName: suplierName, suplierId: suplierId }
        try {
            const response = await sendPost<IPaginatedList<ICapSuplier>>(url, dados, addMessages, setaLoading, navigate);
            setSupliersList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchCategories(categoryName: string, categoryId: number | null) {

        let url = `v1/cap/category/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: categoryName, id: categoryId }
        try {
            const response = await sendPost<IPaginatedList<ICapCategory>>(url, dados, addMessages, setaLoading, navigate);
            setCategoryList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setBill(prevBill => ({ ...prevBill, [name]: valor }));
    };

    const handleChangePayments = (name: string, value: string | number | boolean | Date, index: number) => {
        console.log('name');
        console.log(name);
        console.log('value');
        console.log(value);
        console.log('index');
        console.log(index);
        setBill((prevBill) => {
            return {
                ...prevBill,
                payments:
            prevBill.payments.map((payment, idx) => {
                if (idx !== index) {
                    return payment;
                }
                return {
                    ...payment,
                    [name]: value,
                };
            })
        }
        });
    };

    async function searchBill() {

        let url = `v1/cap/bill/${billId}`
        const dados = {}
        try {
            const response = await sendGet<ICapBill>(url, dados, addMessages, setaLoading, navigate);
            setBill(response)
            searchSupliers('', response.suplierId);
            searchCategories('', response.categoryId);
            console.log(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const columns = [
        { displayName: "Pagamento total", columnName: null },
        { displayName: "Valor pago", columnName: null },
        { displayName: "Data do pagamento", columnName: null },
    ];

    const handleClickDelete = (
        paymentToDelete: ICapPayment) => {
        if (
            confirm(
                `Tem certeza que deseja excluir o pagamento`
            )
        ) {
            /*let indexToDelete = null;
            bill.receipts.map((receipt, idx) => {
                if (receipt.id === receiptToDelete.id) {
                    indexToDelete = idx;
                }
            });
            console.log(indexToDelete);
            if(indexToDelete) {
                bill.receipts.splice(indexToDelete, 1);
            }*/
                setBill((prevBill) => {
                    return {
                      ...prevBill,
                      payments: prevBill.payments.filter((payments) => payments.id !== paymentToDelete.id),
                    };
                  });
        }
        return;
    };



    useEffect(() => {
        setaLoading(true);
        searchBill();
        searchPaymentsStatusList();
        setaLoading(false);
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Fatura à Pagar</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-suplier"
                                    options={supliersList}
                                    getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do cliente como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={supliersList.find(suplier => suplier.id === bill.suplierId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "suplierId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Fornecedor" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchSupliers(newInputValue, bill.suplierId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-status"
                                    options={statusList}
                                    getOptionLabel={(option) => option.label} // Use o nome da categoria como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={statusList.find(billStatus => billStatus.label === bill.billStatus) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "billStatus": newValue?.label ? newValue.label : ''
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Status" required />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descrição"
                                    name="billDescription"
                                    onChange={handleChange}
                                    required

                                    value={bill.billDescription} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Série"
                                    name="billSerie"
                                    onChange={handleChange}
                                    required
                                    value={bill.billSerie}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Codigo"
                                    name="billCode"
                                    onChange={handleChange}
                                    required
                                    value={bill.billCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Data fatura"
                                    value={bill.billDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "billDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='billDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Vencimento fatura"
                                    value={bill.dueDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "dueDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='dueDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-category"
                                    options={categoryList}
                                    getOptionLabel={(option) => option.name} // Use o nome da categoria como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={categoryList.find(category => category.id === bill.categoryId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "categoryId": newValue ? newValue.id : null
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categoria" />}
                                    onInputChange={(event, newInputValue) => {
                                        searchCategories(newInputValue, bill.categoryId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor a Pagar"
                                    value={bill.vlrPagar}
                                    onChange={(values: { target: { name: string; value: string } }) => setBill(prevBill => ({ ...prevBill, "vlrPagar": Number(values.target.value) }))}
                                    name="vlrPagar"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor Desconto"
                                    value={bill.vlrDesconto}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "vlrDesconto": Number(values.target.value) }))}
                                    name="vlrDesconto"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Valor da Devolução"
                                    value={bill.vlrDevolucao}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "vlrDevolucao": Number(values.target.value) }))}
                                    name="vlrDevolucao"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumericFormatCustom
                                    label="Saldo da Duplicata"
                                    value={bill.saldoDuplicata}
                                    onChange={(values: { target: { name: string; value: string } }) =>
                                        setBill(prevBill => ({ ...prevBill, "saldoDuplicata": Number(values.target.value) }))}
                                    name="saldoDuplicata"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TableContainer
                                            component={Paper}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Table size="small" style={{ width: "85%" }}>
                                                <TableHeader
                                                    initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                                                    initialSortedField={sort.split(",")[0]}
                                                    columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                                                    handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                                                />
                                                <TableBody>
                                                    {bill.payments.map((row, index) => (
                                                        <TableRow key={row.id}>
                                                            <CustomTableCell>
                                                                <Box display="flex" alignItems="center">
                                                                    <Checkbox
                                                                        defaultChecked={row.isTotal}
                                                                        onChange={(event) =>
                                                                            handleChangePayments("isTotal", event.target.checked, index)
                                                                        }
                                                                        name="isTotal"
                                                                        value={row.isTotal}
                                                                    />
                                                                </Box>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                <Box display="flex" alignItems="center">
                                                                    <TextField
                                                                        name="paymentValue"
                                                                        onChange={(event) =>
                                                                            handleChangePayments("paymentValue", event.target.value, index)
                                                                        }
                                                                        required
                                                                        type="number"
                                                                        value={row.paymentValue}
                                                                        style={{
                                                                            width: 120,
                                                                        }}
                                                                        inputProps={{
                                                                            style: { paddingTop: 5, paddingBottom: 5 },
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                <Box display="flex" alignItems="center">
                                                                    <DatePicker
                                                                        value={row.paymentDate}
                                                                        onChange={(newValue: Date | null) => {
                                                                            setBill((prevBill) => {
                                                                                return {
                                                                                    ...prevBill,
                                                                                    payments: bill.payments.map((payment, idx) => {
                                                                                        if (idx !== index) {
                                                                                            return payment;
                                                                                        }
    
                                                                                        return {
                                                                                            ...payment,
                                                                                            paymentDate: newValue ? newValue : new Date(),
                                                                                        };
                                                                                    })
                                                                                }
                                                                                
                                                                            });
                                                                        }
                                                                        }
                                                                        renderInput={(params: TextFieldProps) => (
                                                                            <TextField
                                                                                {...params}
                                                                                style={{ width: 140 }}
                                                                                name="paymentDate"
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                <IconButton onClick={() => handleClickDelete(row)}>
                                                                    <Tooltip title="Excluir">
                                                                        <SvgIcon fontSize="small">
                                                                            <TrashIcon aria-label="Excluir" style={iconStyles} />
                                                                        </SvgIcon>
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EditCapBill